import { NgModule } from '@angular/core';
import { CneCurrencyPipe } from './cne-currency/cne-currency.pipe';
import { SafeHtmlPipe } from './save-html/safe-html.pipe';
import { RemoveUnicodePipe } from './remove-unicode/remove-unicode.pipe';

@NgModule({
    declarations: [
        CneCurrencyPipe,
        SafeHtmlPipe,
        RemoveUnicodePipe
    ],
    exports: [
        CneCurrencyPipe,
        SafeHtmlPipe,
        RemoveUnicodePipe,
    ]
})
export class PipesModule { }
