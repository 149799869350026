import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {

  constructor(private x: ModalController) { }

  ngOnInit() { }

  public onConfirm() {
    this.x.dismiss({ isConfirmed: true });
  }

  public onClose() {
    this.x.dismiss({ isConfirmed: false });
  }

}
