const DECIMAL_SEPARATOR = '.';

const as_integer = (n) => {
    const number = String(n);

    let value, exp;
    const tokens = number.split(DECIMAL_SEPARATOR);
    const integer = tokens[0];
    const fractional = tokens[1];
    if (!fractional) {
        const trailing_zeros = integer.match(/0+$/);

        if (trailing_zeros) {
            const length = trailing_zeros[0].length;
            value = integer.substr(0, integer.length - length);
            exp = length;
        } else {
            value = integer;
            exp = 0;
        }
    } else {
        value = parseInt(number.split(DECIMAL_SEPARATOR).join(''), 10);
        exp = fractional.length * -1;
    }

    return {
        value,
        exp
    };
};

// Helpers

const zero = (exp) => {
    return new Array(exp + 1).join('0');
};

const neg_exp = (s, p) => {
    const position = Math.abs(p);
    let str = s;
    const offset = position - str.length;
    let sep = DECIMAL_SEPARATOR;

    if (offset >= 0) {
        str = zero(offset) + str;
        sep = '0.';
    }

    const length = str.length;
    const head = str.substr(0, length - position);
    const tail = str.substring(length - position, length);
    return head + sep + tail;
};

const pos_exp = (str, exp) => {
    const zeros = zero(exp);
    return String(str + zeros);
};

const format = (n, exp) => {
    const num = String(n);
    const func = exp >= 0 ? pos_exp : neg_exp;
    return func(num, exp);
};

export class Decimal {
    num;
    internal;
    as_int;
    constructor(num) {
        this.num = num || 0;
        this.internal = String(this.num);
        this.as_int = as_integer(this.internal);
    }

    add(target) {
        const operands = [this, new Decimal(target)];
        operands.sort((a, b) => {
            return a.as_int.exp - b.as_int.exp;
        });

        const smallest = operands[0].as_int.exp;
        const biggest = operands[1].as_int.exp;

        const x = Number(format(operands[1].as_int.value, biggest - smallest));
        const y = Number(operands[0].as_int.value);

        const result = String(x + y);

        return new Decimal(format(result, smallest));
    }

    sub(target) {
        return new Decimal(this.add(target * -1));
    }

    mul(t) {
        const target = new Decimal(t);
        const result = String(this.as_int.value * target.as_int.value);
        const exp = this.as_int.exp + target.as_int.exp;

        return new Decimal(format(result, exp));
    }

    div(target) {
        const targetT: Decimal = new Decimal(target);

        const smallest = Math.min(this.as_int.exp, targetT.as_int.exp);

        const x: any = this.mul(Math.pow(10, Math.abs(smallest)));
        const y: any = targetT.mul(Math.pow(10, Math.abs(smallest)));

        return new Decimal(x / y);
    }

    toString() {
        return this.internal;
    }

    roundUp(e) {
        return new Decimal(Math.ceil(this.mul(Math.pow(10, e)).toNumber())).div(
            Math.pow(10, e)
        );
    }

    round(e) {
        return new Decimal(Math.round(this.mul(Math.pow(10, e)).toNumber())).div(
            Math.pow(10, e)
        );
    }

    toNumber() {
        return Number(this.internal);
    }
}
