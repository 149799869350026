import { Component, OnInit } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { I18nService } from './cores/i18n.service';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap, map } from 'rxjs/operators';
import { User } from 'firebase';
import { FIREBASE_STRUCT } from './app.constant';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { AppService } from './app.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private i18nService: I18nService,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private appService: AppService,
    private alertController: AlertController,
    private swUpdate: SwUpdate
  ) {
    this.initLang();
    this.initializeApp();

    this.getUser().subscribe(async (user: any) => {
      if (user) {
        const customEvent: CustomEvent = new CustomEvent('authentication', {
          bubbles: true,
          detail: {
            user: {
              user_name: user.full_name,
              user_uid: user.uid
            }
          }
        });
        document.dispatchEvent(customEvent);
      } else {
        const customEvent: CustomEvent = new CustomEvent('authentication', {
          bubbles: true,
          detail: {
            user: null
          }
        });
        document.dispatchEvent(customEvent);
      }
    });
  }
  ngOnInit(): void {
    this.initializeAppUpdate();
  }

  private initLang() {
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    this.i18nService.language = 'vi-VN';
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  initializeAppUpdate() {
    this.platform.ready().then(() => {
      this.checkUpdate();
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 60*1000);
    });
  }

  isAlert: boolean;
  checkUpdate() {
    this.isAlert = false;
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(async () => {
        if (!this.isAlert) {
          this.isAlert = true;
          const alert = await this.alertController.create({
            header: `Cập nhật ứng dụng!`,
            message: `Có phiên bản mới. Vui lòng bấm [Cập nhật] để làm mới ứng dụng!`,
            buttons: [
              {
                text: 'Hủy',
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: 'Cập nhật',
                handler: () => {
                  window.location.reload();
                },
              },
            ],
          });
          await alert.present();
        }
      });
    }
  }

  getUser() {
    return this.fireAuth.authState.pipe(
      switchMap((user: User) => {
        if (user) {
          return this.firestore.collection(FIREBASE_STRUCT.USERS.NODE).doc(user.uid).snapshotChanges().pipe(
            map(doc => ({ uid: user.uid, ...doc.payload.data() as any }))
          );
        } else {
          return of(null);
        }
      })
    );
  }

}
