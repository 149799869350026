import { ApiServiceService } from './api-service.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public apiService: ApiServiceService) { }

  public async getServerTime() {
    try {
      const serverTime: any = JSON.parse(await this.apiService.get('getServerTime'));
      return serverTime.time;
    } catch (error) {
      return new Date().getTime();
    }
  }

  public waitFor(timer: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, timer);
    });
  }
}
