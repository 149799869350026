import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { AppService } from '../app.service';

export function Handling(alertOpts?: AlertOptions): MethodDecorator {
	return (target, prop: string | symbol, descriptor: TypedPropertyDescriptor<any>) => {
		const originalMethod = descriptor.value;
		descriptor.value = async function (...args: any[]) {
			const alertCtrl = AppService.injector.get(AlertController);
			const loadingCtrl = AppService.injector.get(LoadingController);
			const toastCtrl = AppService.injector.get(ToastController);
			const loading = await loadingCtrl.create({
				message: `Vui lòng đợi`,
			});
			const handle = (data?) => {
				loading.present().then(() => {
					return originalMethod.apply(this, args);
				}).then(async msgSuccess => {
					loading.dismiss();
					if (msgSuccess) {
						const toast = await toastCtrl.create({
							message: msgSuccess,
							position: 'top',
							color: 'success',
							duration: 2000
						});
						toast.present();
					}
				}).catch(async (msgError) => {
					loading.dismiss();
					console.log(msgError);
					const toast = await toastCtrl.create({
						message: msgError.message ? msgError.message : msgError,
						position: 'top',
						color: 'danger',
						duration: 2000
					});
					toast.present();
				});
			};
			if (alertOpts) {
				const alert = await alertCtrl.create(alertOpts);
				alert.present();
				alert.onDidDismiss().then(result => {
					const { data, role } = result;
					if (role === 'ok') {
						handle(data);
					}
				});
			} else {
				handle();
			}

		};
		return descriptor;
	};
}
