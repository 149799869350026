export const ORDER_DEFAULT = {
    NGAYBATDAUTINHLUUKHO: {
        KEY: 'BioN6OJ0VDtswg37bPYy',
    },
    NGAYTUDONGXOASANPHAM: {
        KEY: 'DrsAg36opEXjsRXvxbe9'
    },
    TYGIATHUC: {
        KEY: 'Pfrruaf39VjFSYc5oRIY'
    },
    NGAYTUDONGHOANTHANH: {
        KEY: 'VNhrfymq0UhhnGJksrut'
    },
    PHICHUYENKHOAN: {
        KEY: 'Z7BUTCLnGn6gXwMioEXX'
    },
    PHIDICHVUTOITHIEU: {
        KEY: 'cK61l9jySLeF3IzvWW2r'
    },
    NGAYTUDONGXOADON: {
        KEY: 'fvBdRAhvTAdH165HJtbZ'
    },
    PHILUUKHO: {
        KEY: 'hTOBXuzefiQWDmqlQJWj'
    },
    TYGIABAOKHACH: {
        KEY: 'qD2eyt3ZNdmQkXNNvayJ'
    },
    PHIDONGGOKGTIEPTHEO: {
        KEY: 'uw4Kdk6QXb1XiTSBpoPd'
    },
    PHIDONGGOKGDAU: {
        KEY: 'vcyFUSotXvoM3WA1KHPn'
    }
};

export const PARAMETER_VN_WAREHOUSE = {
    CUOCVANCHUYENBAO: {
        KEY: 'J3XJxionaJyFK3oUViUo',
    },
    PHIPHATSINH: {
        KEY: 'LICTo04qGg9ayRtwLVCp'
    },
    CUOCXEOM: {
        KEY: 'rIw71pR7irKDj3mayMyi'
    }
};

export const SG_WAREHOUSE = {
    id: 'SG',
    value: 'Kho SG (CPN từ kho SG)'
};

export const HN_WAREHOUSE = {
    id: 'HN',
    value: 'Kho HN (CPN từ kho HN)'
};
