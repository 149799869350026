import { Component, OnInit, OnDestroy } from '@angular/core';
import { Handling } from 'src/app/decorators/handling';
import { Subject, Observable } from 'rxjs';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { FIREBASE_STRUCT } from 'src/app/app.constant';
import { map, first, takeUntil, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { Decimal } from 'src/app/models/app.models';

@Component({
  selector: 'app-consignment-order-add',
  templateUrl: './consignment-order-add.component.html',
  styleUrls: ['./consignment-order-add.component.scss'],
})
export class ConsignmentOrderAddComponent implements OnInit, OnDestroy {
  consignmentOrders: any[];
  authInfo: any;
  unsubscribe$ = new Subject();
  paramInssurrance: any[];
  ruleCreateOrderHtml = '';

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private auth: AngularFireAuth,
    private fs: AngularFirestore,
    private loadingCtrl: LoadingController,
    // private toastCtrl: ToastController,
    // private navParams: NavParams,
    // private translateService: TranslateService,
    // private commonService: CommonService,
    private api: ApiServiceService
  ) {
    this.getContent().subscribe((content: any) => {
      if (content) {
        this.ruleCreateOrderHtml = content.content_html;
      } else {
        this.ruleCreateOrderHtml = '';
      }
    });
  }

  async ngOnInit() {
    this.paramInssurrance = await this.getParamInsuarance();
    this.consignmentOrders = [];
    const consignmentOrder = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      require: null,
      item_value: null,
      is_insurrance: false,
      insurrance_key: this.paramInssurrance[1].value,
      insurrance_text: null,
      insurrance_fee: null,
      action: null
    };
    this.consignmentOrders.push(consignmentOrder);
    this.getUserInfo(this.auth.auth.currentUser.uid).subscribe(u => {
      this.authInfo = u;
      console.log(u)
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUserInfo(userUid: string) {
    return this.fs.collection(`${FIREBASE_STRUCT.USERS.NODE}`).doc<any>(userUid).valueChanges().pipe(
      switchMap(user => {
        let ob: Observable<any>;
        const vipType = user.vip.type;
        if (vipType === 1) {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(user.vip.uid).valueChanges();
        } else {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_SPECIAL.NODE).doc(user.vip.uid).valueChanges();
        }
        return ob.pipe(
          map(vip => {
            return { ...user, uid: userUid, vip: { ...vip, type: vipType, uid: user.vip.uid } };
          })
        );
      })
    );
  }

  onAddTemp() {
    const consignmentOrder = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      require: null,
      item_value: null,
      is_insurrance: false,
      insurrance_key: this.paramInssurrance[1].value,
      insurrance_text: null,
      insurrance_fee: null,
      action: null
    };
    this.consignmentOrders.push(consignmentOrder);
  }

  async onSubmit() {
    try {
      this.alertCtrl.create({
        header: 'Xác nhận tạo đơn ký gửi?',
        message: `<div style="color: initial; height: 500px; overflow: auto;">${this.ruleCreateOrderHtml}</div>`,
        buttons: [
          /* {
            text: 'Bằng việc click ĐỒNG Ý là quý khách chấp nhận thực hiện theo tất cả các quy định của công ty',
            cssClass: 'text-confirm',
            handler: () => {
              return false;
            }
          }, */
          {
            text: 'Đồng ý',
            cssClass: 'ok-btn',
            handler: () => {
              this.loadingCtrl.create({
                duration: 5000,
                spinner: 'dots'
              }).then((loading) => {
                loading.present().then(async () => {
                  const check = {};
                  for (let i = 0; i < this.consignmentOrders.length; i++) {
                    this.consignmentOrders[i].transport_code = String(this.consignmentOrders[i].transport_code).trim();
                    const consignmentOrder = this.consignmentOrders[i];

                    if (!consignmentOrder.transport_code) {
                      throw new Error(`Chưa nhập mã vận đơn tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.content) {
                      throw new Error(`Chưa nhập nội dung hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.product_quantity) {
                      throw new Error(`Chưa nhập số lượng hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.product_link) {
                      throw new Error(`Chưa nhập link hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.shop_name) {
                      throw new Error(`Chưa nhập thương hiệu tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    const isDuplicateTransportCode: any = await this.checkTransport(consignmentOrder.transport_code);
                    if (isDuplicateTransportCode.length) {
                      throw new Error(`Mã vận đơn ${consignmentOrder.transport_code} đã được nhập ở đơn hàng ${isDuplicateTransportCode.toString()}. Vui lòng nhập mã vận đơn khác`);
                    }

                    if (check[consignmentOrder.transport_code]) {
                      throw new Error(`Mã vận đơn '${consignmentOrder.transport_code}' bị trùng lăp. Vui lòng kiểm tra lại thông tin`);
                    } else {
                      check[consignmentOrder.transport_code] = true;
                    }
                  }

                  const user = { ...this.authInfo };
                  await this.api.post('consignment-orders/createNewConsignment', { consignments: this.consignmentOrders, user_uid: user.uid });

                  this.modalCtrl.dismiss();
                  return '<div>• Tạo đơn ký gửi thành công</div>';
                }).then((msgSuccess) => {
                  this.alertCtrl.create({
                    header: 'Thông báo',
                    message: `${msgSuccess}`,
                    buttons: [
                      {
                        role: 'cancel',
                        text: 'OK',
                        cssClass: 'cancel-btn'
                      }
                    ]
                  }).then(alert => {
                    alert.present();
                    loading.dismiss();
                  });
                }).catch(msgError => {
                  this.alertCtrl.create({
                    header: 'Lỗi',
                    message: `${msgError.message}`,
                    buttons: [
                      {
                        role: 'cancel',
                        text: 'OK',
                        cssClass: 'ok-btn'
                      }
                    ]
                  }).then(alert => {
                    alert.present();
                    loading.dismiss();
                  });
                });
              });
            }
          },
          {
            role: 'cancel',
            cssClass: 'cancel-btn',
            text: 'Hủy'
          }
        ]
      }).then(alert => {
        alert.present();
      });
    } catch (error) {
      throw error;
    }
  }

  async checkTransport(transportCode) {
    const fs = firebase.firestore();
    const consignmentCode = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).where('transport_code', '==', transportCode).get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.get('consignment_code'));
      });
      return rs;
    });

    const orderCode = await fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).where('transport_code', '==', transportCode).get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.get('order_code'));
      });
      return rs;
    });

    return new Promise((resolve, reject) => {
      if (consignmentCode.length) {
        resolve(consignmentCode);
      } else if (orderCode.length) {
        resolve(orderCode);
      } else {
        resolve([]);
      }
    });

  }

  onDeleteTemp(index) {
    this.consignmentOrders.splice(index, 1);
  }

  onCheckInsuarance(consignmentOrder) {
    consignmentOrder.is_insurrance = !consignmentOrder.is_insurrance;
    if (consignmentOrder.is_insurrance) {
      const money = consignmentOrder.item_value || 0;
      consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
    } else {
      consignmentOrder.insurrance_fee = 0;
      consignmentOrder.insurrance_key = this.paramInssurrance[1].value;
    }
  }

  onChangeItemValue(ev: any, consignmentOrder) {
    const money = Number(ev) || 0;
    consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
  }

  onSelectInsuarance(consignmentOrder) {
    const money = consignmentOrder.item_value || 0;
    consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
  }

  getConsignment(uid) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.CONSIGNMENTS.NODE}/${uid}`).snapshotChanges().pipe(
      map(sn => ({ uid: sn.payload.id, ...sn.payload.data() })),
      first()
    ).toPromise();
  }

  getParamInsuarance() {
    return this.fs.collection<any>(`${FIREBASE_STRUCT.PARAMETER_CONSIGNMENT_ORDER_INSURRANCE_FEE.NODE}`).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();
  }

  getContent() {
    return this.fs.collection('PARAMETER_CONTENT').doc('home_create_consignment_order_rule').valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

}
