import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [

  // HOT FIX for extension
  {
    path: 'home/login',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  // HOT FIX for extension
  {
    path: 'main',
    redirectTo: 'nhtb',
    pathMatch: 'prefix'
  },

  {
    path: '',
    redirectTo: 'nhtb',
    pathMatch: 'full'
  },
  {
    path: 'nhtb',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'access-denied',
  //   loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedPageModule)
  // },
  // {
  //   path: 'reset-password',
  //   loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  // },
  // {
  //   path: 'server-error',
  //   loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorPageModule)
  // },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password-sp/forgot-password-sp.module').then(m => m.ForgotPasswordSpPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then(m => m.SigninPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  // {
  //   path: 'account-verify',
  //   loadChildren: () => import('./account-verify/account-verify.module').then(m => m.AccountVerifyPageModule),
  //   // canActivate: [AuthGuard]
  // },
  // {
  //   path: '**',
  //   loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
